import chips1 from "../../assets/svg-icons/lucky/chips1.svg";
import chips2 from "../../assets/svg-icons/lucky/chips2.svg";
import chips3 from "../../assets/svg-icons/lucky/chips3.svg";
import chips4 from "../../assets/svg-icons/lucky/chips4.svg";
import chips5 from "../../assets/svg-icons/lucky/chips5.svg";
import chips6 from "../../assets/svg-icons/lucky/chips6.svg";
import x_red from "../../assets/svg-icons/lucky/x_red.svg";
import iconScratch from "../../assets/images/icon_scratch.webp";
import buffalo from "../../assets/images/luckyWheel/buffalo.webp";
import cheetah from "../../assets/images/luckyWheel/cheetah.webp";
import elephant from "../../assets/images/luckyWheel/elephant.webp";
import hippo from "../../assets/images/luckyWheel/hippo.webp";
import lion from "../../assets/images/luckyWheel/lion.webp";
import panda from "../../assets/images/luckyWheel/panda.webp";
import rhino from "../../assets/images/luckyWheel/rhino.webp";
import tiger from "../../assets/images/luckyWheel/tiger.webp";

export default {
    chips:[
        {
            name:'losing',
            img:x_red,
            msg:'0',
        },
        {
            img:chips2,
            msg:'0',
        },
        {
            img:chips1,
            msg:'0',
        },
        {
            img:chips3,
            msg:'0',
        },
        {
            img:chips4,
            msg:'0',
        },
        {
            img:chips5,
            msg:'0',
        },
        {
            img:chips6,
            msg:'0',
        },
        {
            img:chips3,
            msg:'0',
        },
        {
            img:chips1,
            msg:'0',
        },
        {
            name:'scratch',
            img:iconScratch,
            msg:'Scratch',
        },
    ],
    scratch:[
        {
            name:'buffalo',
            img:buffalo,
        },
        {
            name:'cheetah',
            img:cheetah,
        },
        {
            name:'panda',
            img:panda,
        },
        {
            name:'hippo',
            img:hippo,
        },
        {
            name:'elephant',
            img:elephant,
        },
        {
            name:'lion',
            img:lion,
        },
        {
            name:'rhino',
            img:rhino,
        },
        {
            name:'panda',
            img:panda,
        },
        {
            name:'tiger',
            img:tiger,
        },
        {
            name:'losing',
            img:x_red,
            msg:'Losing',
        }
    ]
}

