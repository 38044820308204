<template>
  <div>
    <app-header
      :style="isJackbotPage?'height:0; padding:0;':''"
    />
    <transition name="fade">
      <router-view />
    </transition>
    <app-footer />
  </div>
</template>

<script>
import AppHeader from "./header/app-header";
import AppFooter from "./footer/app-footer";
export default {
  components: { AppHeader, AppFooter },
  data() {
    return {
      isJackbotPage: false,
      noticeModalVisible: localStorage.getItem("notice-accepted") ? !JSON.parse(localStorage.getItem("notice-accepted")) : true
    };
  }, watch: {
    $route(to, from) {
      this.noticeModalVisible = this.noticeModalVisible && to.name !== "terms" && to.name !== "privacy";

      this.isJackbotPage = to.path.includes("jackbot");
      console.log(to.name);
    }
  },
  mounted() {
    this.isJackbotPage = window.location.pathname.includes("jackbot");
  },
  methods: {
    handleNoticeModalClose() {
      this.noticeModalVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>